//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import HeroFeature from '~/components/HeroFeature.vue';

export default {
  name: 'Experience_Skills',
  components: {
    HeroFeature,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
  },
};
